import React from "react"

import Layout from "../components/layout"
import Banner from "../components/banner-inner"
import SEO from "../components/seo"

import "../styles/page.scss"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Banner title="404" />
    <section className="page-section section-404">
        <div className="container">
            <div className="section-block text-center">
              <h2>NOT FOUND</h2>
              <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </div>
        </div>
    </section>    
  </Layout>
)

export default NotFoundPage
