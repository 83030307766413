import React, { Component } from "react"
import { Link } from "gatsby"
import axios from "axios"

import TrackVisibility from "react-on-screen"

import { API_EN } from "../utils/config"

class Banner extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      data: null,
    }
  }
  async componentDidMount() {
    this._isMounted = true
    const get = endpoint => axios.get(`${API_EN}/${endpoint}`)
    const { data: item } = await get(`settings`)
    if (this._isMounted) {
      this.setState({
        data: item.data.social,
      })
    }
  }
  componentWillUnmount() {
    this._isMounted = false
  }
  goBack() {
    window.history.back()
  }
  render() {
    const social = this.state.data

    return (
      <TrackVisibility offset={350} once tag="section" className="banner-view">
        {({ isVisible }) => (
          <section className="banner-inner">
            <div className="container">
              <div className="inner">
                <ol className="social">
                  {social && social.facebook_link ? (
                    <li>
                      <a href={social.facebook_link} target="_blank">
                        <i className="icon icon-graphics-icon-fb"></i>
                      </a>
                    </li>
                  ) : null}
                  {social && social.instagram_link ? (
                    <li>
                      <a href={social.instagram_link} target="_blank">
                        <i className="icon icon-graphics-icon-insta"></i>
                      </a>
                    </li>
                  ) : null}
                  {social && social.twitter_link ? (
                    <li>
                      <a href={social.twitter_link} target="_blank">
                        <i className="icon icon-graphics-icon-twtr"></i>
                      </a>
                    </li>
                  ) : null}
                  {social && social.youtube_link ? (
                    <li>
                      <a href={social.youtube_link} target="_blank">
                        <i className="icon icon-graphics-icon-youtube"></i>
                      </a>
                    </li>
                  ) : null}

                  {social && social.whatsapp_link ? (
                    <li>
                      <a href={social.whatsapp_link} target="_blank">
                        <i class="icon icon-social-whatsapp icon-2-social-whatsapp"></i>
                      </a>
                    </li>
                  ) : null}
                </ol>
                <div className="line __left"></div>
                <div className="banner-title">
                  <h1>{this.props.title}</h1>
                </div>
              </div>

              {/* <Link to="/" className="back"><i className="icon icon-graphics-arrow-icon-prev"></i><span>Back to Home</span></Link> */}
              <a
                onClick={
                  this.props.goBack
                    ? this.props.handleClick
                    : this.goBack.bind(this)
                }
                className="back"
              >
                <i className="icon icon-graphics-arrow-icon-prev"></i>
                <span>Back</span>
              </a>
            </div>
          </section>
        )}
      </TrackVisibility>
    )
  }
}

export default Banner
